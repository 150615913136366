var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"choice"},[_c('a-radio-group',{on:{"change":_vm.onChange},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('a-radio',{attrs:{"value":1}},[_c('strong',[_vm._v(" Phone")])]),_c('a-radio',{attrs:{"value":2}},[_c('strong',[_vm._v("Email")])])],1)],1),_c('div',{staticClass:"form-fill"},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',[(_vm.showPhone)?_c('div',[_c('a-form-item',{attrs:{"label":"Phone Number"}},[_c('vue-tel-input',_vm._b({model:{value:(_vm.vueTel.phone),callback:function ($$v) {_vm.$set(_vm.vueTel, "phone", $$v)},expression:"vueTel.phone"}},'vue-tel-input',_vm.vueTel.props,false))],1)],1):_c('div',[_c('a-form-item',{attrs:{"label":"E-mail"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'email',
                  {
                    rules: [
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail!',
                      } ],
                  } ]),expression:"[\n                  'email',\n                  {\n                    rules: [\n                      {\n                        type: 'email',\n                        message: 'The input is not valid E-mail!',\n                      },\n                      {\n                        required: true,\n                        message: 'Please input your E-mail!',\n                      },\n                    ],\n                  },\n                ]"}]})],1)],1)])],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"xs":24,"md":12}},[_c('a-form-item',{attrs:{"label":"First Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'firstName',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your first name!',
                    },
                    {
                      min: 3,
                      max: 10,
                      message: 'Length should be 3 to 10',
                    } ],
                } ]),expression:"[\n                'firstName',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input your first name!',\n                    },\n                    {\n                      min: 3,\n                      max: 10,\n                      message: 'Length should be 3 to 10',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"xs":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Last Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'lastName',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your last name!',
                    },
                    {
                      min: 3,
                      max: 10,
                      message: 'Length should be 3 to 10',
                    } ],
                } ]),expression:"[\n                'lastName',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input your last name!',\n                    },\n                    {\n                      min: 3,\n                      max: 10,\n                      message: 'Length should be 3 to 10',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"xs":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Password","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      min: 6,
                      max: 12,
                      message: 'Length should be 6 to 12',
                    },
                    {
                      validator: _vm.validateToNextPassword,
                    } ],
                } ]),expression:"[\n                'password',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input your password!',\n                    },\n                    {\n                      min: 6,\n                      max: 12,\n                      message: 'Length should be 6 to 12',\n                    },\n                    {\n                      validator: validateToNextPassword,\n                    },\n                  ],\n                },\n              ]"}],attrs:{"type":"password"}})],1)],1),_c('a-col',{attrs:{"xs":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Confirm Password","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'confirm',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    {
                      validator: _vm.compareToFirstPassword,
                    } ],
                } ]),expression:"[\n                'confirm',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please confirm your password!',\n                    },\n                    {\n                      validator: compareToFirstPassword,\n                    },\n                  ],\n                },\n              ]"}],attrs:{"type":"password"},on:{"blur":_vm.handleConfirmBlur}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"xs":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Gender"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'gender',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please select your gender!',
                    } ],
                } ]),expression:"[\n                'gender',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please select your gender!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"Select your gender"}},[_c('a-select-option',{attrs:{"value":"male"}},[_vm._v(" Male ")]),_c('a-select-option',{attrs:{"value":"female"}},[_vm._v(" Female ")]),_c('a-select-option',{attrs:{"value":"other"}},[_vm._v(" Other ")])],1)],1)],1),_c('a-col',{attrs:{"xs":24,"md":12}},[_c('a-form-item',{attrs:{"label":"Date of Birth"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['date-picker', _vm.config]),expression:"['date-picker', config]"}],attrs:{"format":_vm.moment().format('DD/MM/YYYY')}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',[_c('a-form-item',{attrs:{"label":"Address"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'address',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your address!',
                      whitespace: true,
                    } ],
                } ]),expression:"[\n                'address',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please input your address!',\n                      whitespace: true,\n                    },\n                  ],\n                },\n              ]"}]})],1)],1)],1)],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.tailFormItemLayout,false),[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['agreement', { valuePropName: 'checked' }]),expression:"['agreement', { valuePropName: 'checked' }]"}]},[_vm._v(" I have read the "),_c('a',{attrs:{"href":""}},[_vm._v(" agreement ")])])],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.tailFormItemLayout,false),[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Submit ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
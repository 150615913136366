<template>
  <div class="form">
    <a-form :form="form" @submit="handleSubmit">
      <div class="choice">
        <a-radio-group v-model="value" @change="onChange">
          <a-radio :value="1"> <strong> Phone</strong> </a-radio>
          <a-radio :value="2"> <strong>Email</strong> </a-radio>
        </a-radio-group>
      </div>
      <div class="form-fill">
        <a-row :gutter="24">
          <a-col>
            <div v-if="showPhone">
              <a-form-item label="Phone Number">
                <!-- <a-input
                  v-decorator="[
                    'phone',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your phone number!',
                        },
                      ],
                    },
                  ]"
                  style="width: 100%"
                >
                  <a-select
                    slot="addonBefore"
                    v-decorator="['prefix', { initialValue: '856' }]"
                    style="width: 70px"
                  >
                    <a-select-option value="856"> +856 </a-select-option>
                    <a-select-option value="166"> +166 </a-select-option>
                  </a-select>
                </a-input> -->

                <vue-tel-input
                  v-model="vueTel.phone"
                  v-bind="vueTel.props"
                ></vue-tel-input>

                <!-- <vue-phone-number-input
                  v-model="vuePhone.phone"
                  v-bind="vuePhone.props"
                ></vue-phone-number-input> -->
              </a-form-item>
            </div>
            <div v-else>
              <a-form-item label="E-mail">
                <a-input
                  v-decorator="[
                    'email',
                    {
                      rules: [
                        {
                          type: 'email',
                          message: 'The input is not valid E-mail!',
                        },
                        {
                          required: true,
                          message: 'Please input your E-mail!',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :xs="24" :md="12">
            <a-form-item label="First Name">
              <a-input
                v-decorator="[
                  'firstName',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your first name!',
                      },
                      {
                        min: 3,
                        max: 10,
                        message: 'Length should be 3 to 10',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :md="12">
            <a-form-item label="Last Name">
              <a-input
                v-decorator="[
                  'lastName',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your last name!',
                      },
                      {
                        min: 3,
                        max: 10,
                        message: 'Length should be 3 to 10',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :xs="24" :md="12">
            <a-form-item label="Password" has-feedback>
              <a-input
                v-decorator="[
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                      {
                        min: 6,
                        max: 12,
                        message: 'Length should be 6 to 12',
                      },
                      {
                        validator: validateToNextPassword,
                      },
                    ],
                  },
                ]"
                type="password"
              />
            </a-form-item>
          </a-col>
          <a-col :xs="24" :md="12">
            <a-form-item label="Confirm Password" has-feedback>
              <a-input
                v-decorator="[
                  'confirm',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      {
                        validator: compareToFirstPassword,
                      },
                    ],
                  },
                ]"
                type="password"
                @blur="handleConfirmBlur"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :xs="24" :md="12">
            <a-form-item label="Gender">
              <a-select
                v-decorator="[
                  'gender',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select your gender!',
                      },
                    ],
                  },
                ]"
                placeholder="Select your gender"
              >
                <a-select-option value="male"> Male </a-select-option>
                <a-select-option value="female"> Female </a-select-option>
                <a-select-option value="other"> Other </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xs="24" :md="12">
            <a-form-item label="Date of Birth">
              <a-date-picker
                :format="moment().format('DD/MM/YYYY')"
                v-decorator="['date-picker', config]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col>
            <a-form-item label="Address">
              <a-input
                v-decorator="[
                  'address',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your address!',
                        whitespace: true,
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <a-form-item v-bind="tailFormItemLayout">
        <a-checkbox v-decorator="['agreement', { valuePropName: 'checked' }]">
          I have read the
          <a href=""> agreement </a>
        </a-checkbox>
      </a-form-item>
      <a-form-item v-bind="tailFormItemLayout">
        <a-button type="primary" html-type="submit"> Submit </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import moment from "moment";
// import VuePhoneNumberInput from "vue-phone-number-input";
// import "vue-phone-number-input/dist/vue-phone-number-input.css";
// import VueTelInput from "vue-tel-input";

export default {
  name: "RegisterForm",

  components: {},

  data() {
    return {
      vueTel: {
        phone: "",
        props: {
          preferredCountries: ["US", "GB"],
          placeholder: "Enter your phone",
          mode: "international",
          inputOptions: {
            showDialCode: true,
          },
          disabledFormatting: false,
          wrapperClasses: "country-phone-input",
        },
      },
      // vuePhone: {
      //   phone: "",
      //   props: {
      //     clearable: true,
      //     fetchCountry: true,
      //     preferredCountries: ["US", "GB"],
      //     noExample: false,
      //     translations: {
      //       countrySelectorLabel: "Country code",
      //       countrySelectorError: "Error",
      //       phoneNumberLabel: "Enter your phone",
      //       example: "Example:",
      //     },
      //   },
      // },
      value: 1,
      showPhone: true,
      confirmDirty: false,
      autoCompleteResult: [],
      moment,
      config: {
        rules: [
          { type: "object", required: true, message: "Please select date!" },
        ],
      },

      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          md: { span: 12 },
        },
        wrapperCol: {
          xs: { span: 24 },
          md: { span: 12 },
        },
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      },
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  methods: {
    onChange(e) {
      if (this.value == 1) {
        this.showPhone = true;
      } else {
        this.showPhone = false;
      }
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          this.$router.push("/");
        }
      });
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback("Two passwords that you enter is inconsistent!");
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    },
    handleWebsiteChange(value) {
      let autoCompleteResult;
      if (!value) {
        autoCompleteResult = [];
      } else {
        autoCompleteResult = [".com", ".org", ".net"].map(
          (domain) => `${value}${domain}`
        );
      }
      this.autoCompleteResult = autoCompleteResult;
    },
  },
};
</script>
<style lang="scss">
.form {
  margin: auto;
  padding: 20px;
  text-align: center;
  max-width: 700px;
  // box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);

  .choice {
    font-size: 30px;
    margin: auto;
    padding: 10px;
    background: #008000;
    border-radius: 5px;
    max-width: 700px;
  }

  .form-fill {
    text-align: left;
  }

  .country-phone-input .dropdown:focus {
    outline: none;
    color: grey;
  }
}
</style>

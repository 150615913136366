<template>
  <div>
    <div class="content-home-header">
      <div class="home-welcome">
        <!-- <h3>Welcome to Laospt</h3> -->
        <!-- <p class="text-lg">
          COVID-19 PT CARE INSURANCE POLICY GENERAL CONDITION.
        </p> -->
      </div>
      <HomeSlider />
    </div>
    <div class="content">
      <div class="insurance">
        <div style="margin: 10px; border-radius: 10px; padding: 10px 0 10px 0">
          <h4>ປະກັນໄພ ສຸຂະພາບແຮງງານ ສຳລັບຄົນຕ່າງປະເທດ</h4>
          <h4>Labor Health Insurance for Foreigner</h4>
        </div>
        <div style="margin: 20px 0 20px 0">
          <a-row :gutter="24">
            <a-col :xs="24" :lg="8">
              <div class="card-insurance">
                <h6>ຄ່າທຳນຽມປະກັນໄພ 500.000 ກີບ/ປີ</h6>
                <h6>Insurance fee 500.000 LAK/Year</h6>
              </div>
            </a-col>
            <a-col :xs="24" :lg="8">
              <div class="card-insurance">
                <h6>ຄຸ້ມຄອງສູງສຸດ 4 ຄັ້ງ/ສັນຍາ</h6>
                <h6>Maximum protect 5 times/Contract</h6>
              </div>
            </a-col>
            <a-col :xs="24" :lg="8">
              <div class="card-insurance">
                <h6>ວົງເງີນຄຸ້ມຄອງສູງສຸດ 50.000.000 ກີບ/ຄົນ</h6>
                <h6>Maximum coverage 50.000.000 LAK/Person</h6>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div
        class="benefit"
        style="box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.12); margin-top: 15px"
      >
        <a-row :gutter="24">
          <a-col :span="12" :xs="24" :md="12">
            <div
              style="margin: 10px; border-radius: 10px; padding: 10px 0 10px 0"
            >
              <a-row type="flex" justify="center" align="middle" :gutter="24">
                <a-col :span="6" style="width: fit-content">
                  <a-space align="center">
                    <a-icon
                      type="plus-circle"
                      style="
                        font-size: 40px;
                        margin-left: 10px;
                        padding-left: 5px;
                        color: #008000;
                      "
                    />
                  </a-space>
                </a-col>
                <a-col :span="18">
                  <div style="text-align: left">
                    <h6>ຜົນປະໂຫຍດ ຂອງປະກັນໄພສຸຂະພາບແຮງງານສຳ ລັບຄົນຕ່າງປະເທດ</h6>
                    <h6>Benefit of Labor Health Insurance for Foreigner</h6>
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-col>
          <a-col :span="12" :xs="24" :md="12">
            <div
              style="margin: 10px; border-radius: 10px; padding: 10px 0 10px 0"
            >
              <a-row type="flex" justify="center" align="middle" :gutter="24">
                <a-col :span="6" style="width: fit-content">
                  <a-space align="center">
                    <a-icon
                      type="check-circle"
                      style="
                        font-size: 40px;
                        margin-left: 10px;
                        padding-left: 5px;
                        color: #008000;
                      "
                    />
                  </a-space>
                </a-col>
                <a-col :span="18">
                  <div style="text-align: left">
                    <h6>ລາຍລະອຽດກ່ຽວກັບການຄຸ້ມຄອງ</h6>
                    <h6>Information about the insurance benefit</h6>
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-row>
        <a-row type="flex" justify="center" align="middle" :gutter="24">
          <a-col :span="24" :sm="24" :md="20" :lg="18">
            <a-card
              style="
                border-radius: 20px;
                box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.12);
                margin-bottom: 20px;
              "
            >
              <img
                slot="cover"
                alt="benefit"
                src="images/ads.jpg"
                style="border-radius: 15px"
              />
            </a-card>
          </a-col>
        </a-row>
      </div>
      <div
        class="benefit"
        style="box-shadow: 1px 4px 5px rgba(0, 0, 0, 0.12); margin-top: 15px"
      >
        <a-row :gutter="24">
          <a-col :span="12" :xs="24" :md="12">
            <div
              style="margin: 10px; border-radius: 10px; padding: 10px 0 10px 0"
            >
              <a-row type="flex" justify="center" align="middle" :gutter="24">
                <a-col :span="6" style="width: fit-content">
                  <a-space align="center">
                    <a-icon
                      type="home"
                      style="
                        font-size: 40px;
                        margin-left: 10px;
                        padding-left: 5px;
                        color: #008000;
                      "
                    />
                  </a-space>
                </a-col>
                <a-col :span="18">
                  <div style="text-align: left">
                    <h6>ໂຮງໝໍທີ່ຮອງຮັບການຮັກສາໃນທົ່ວປະເທດ</h6>
                    <h6>Supported Hospital around country</h6>
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-col>
          <a-col :span="12" :xs="24" :md="12">
            <div
              style="margin: 10px; border-radius: 10px; padding: 10px 0 10px 0"
            >
              <a-row type="flex" justify="center" align="middle" :gutter="24">
                <a-col :span="6" style="width: fit-content">
                  <a-space align="center">
                    <a-icon
                      type="check-circle"
                      style="
                        font-size: 40px;
                        margin-left: 10px;
                        padding-left: 5px;
                        color: #008000;
                      "
                    />
                  </a-space>
                </a-col>
                <a-col :span="18">
                  <div style="text-align: left">
                    <h6>
                      ທ່ານສາມາດເຂົ້າຮັບການຮັກສາໄດ້ທີ່ໂຮງໝໍຫຼັກປະຈຳແຕ່ລະແຂວງທົ່ວປະເທດ
                    </h6>
                    <h6>
                      You can receive treatment at the main hospital in each
                      province throughout the country
                    </h6>
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-row>
        <a-row type="flex" justify="center" align="middle" :gutter="24">
          <a-col :span="24" :sm="24" :md="20" :lg="18">
            <a-card style="border-radius: 15px; margin-bottom: 30px">
              <img
                slot="cover"
                alt="hospital"
                src="images/ads2.jpg"
                style="border-radius: 15px"
              />
            </a-card>
          </a-col>
        </a-row>
      </div>

      <!-- <h3 class="mt-30 mb-20">Register here to get your care from us</h3>
      <RegisterForm /> -->
    </div>
  </div>
</template>

<script>
import HomeSlider from "../components/Slider/HomeSlider.vue";
import RegisterForm from "../components/Form/RegisterForm.vue";

export default {
  components: {
    HomeSlider,
    RegisterForm,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.content-home-header {
  height: fit-content;
  margin-top: -110.8px;
  padding-top: 120.8px;
  padding-bottom: 10px;
  border-radius: 12px;
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
  background-size: cover;
  background-position: center;
  text-align: center;

  .home-welcome {
    padding: 10px;
    position: relative;
    text-align: center;
  }
}

.content {
  text-align: center;

  .insurance {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);

    .card-insurance {
      background-color: #008000;
      margin: 10px 20px 20px 20px;
      border-radius: 15px;
      padding: 10px 0 10px 0;

      // width: 350px;
      // height: 80px;
      // background-color: red;
      // font-weight: bold;
      position: relative;
      animation: my_move 3s infinite;
      animation-delay: 3s;

      @keyframes my_move {
        from {
          left: -5px;
        }

        to {
          left: 2px;
        }
      }
    }

    h6 {
      color: #ffff;
      white-space: nowrap;
    }
  }
}
</style>

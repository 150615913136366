<template>
  <div class="slider">
    <a-carousel autoplay dots-class="slick-dots slick-thumb">
      <!-- <a slot="customPaging" slot-scope="props">
            <img :src="getImgUrl(props.i)" />
              </a> -->
      <!-- <div v-for="item in 4"> -->
      <div v-for="item in baseUrls" :key="item.id">
        <img :src="item.path" style="border-radius: 5px" />
        <!-- <img :src="baseUrl.path + 'abstract0' + item + '.jpg'" /> -->
      </div>
    </a-carousel>
  </div>
</template>
<script>
const baseUrls = [
  {
    id: 1,
    path: "images/slider1.jpeg",
  },
  {
    id: 2,
    path: "images/slider2.jpeg",
  },
];
export default {
  data() {
    return {
      baseUrls,
    };
  },
  methods: {
    getImgUrl(i) {
      return `${baseUrl}abstract0${i + 1}.jpg`;
    },
  },
};
</script>
<style scoped>
/* For demo */
.ant-carousel >>> .slick-dots {
  /* height: 500px; */
  height: auto;
}

.ant-carousel >>> .slick-slide img {
  /* border: 5px solid #fff; */
  display: block;
  margin: auto;
  max-width: 80%;
}

.ant-carousel >>> .slick-thumb {
  bottom: -45px;
}

.ant-carousel >>> .slick-thumb li {
  width: 60px;
  height: 45px;
}

.ant-carousel >>> .slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}

.ant-carousel >>> .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}

.slider {
  margin: 20px 0 15px 0;
  width: 100%;
}
</style>
